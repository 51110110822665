<template>
    <div class="soon-page">
        <div class="center">Coming Soon...</div>
    </div>
</template>
<script>
export default {
    name: 'Soon'
}
</script>
<style lang="scss">
    .soon-page{
        position: absolute;
        width: 100%;
        height: 100vh;

        .center{
            margin-top: 40vh;
            text-align: center;
            font-size: 16px;
            color: #fff;
            line-height: 1.5;
        }
    }
</style>


